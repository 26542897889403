@import '~antd/lib/style/themes/default.less';
@import "~antd/dist/antd.less";
@import "./var.less";
//@import "~antd/dist/antd.css";


.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.ant-card-actions {
  background: inherit;
}

.admin-page-logo {
  height: 64px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  background-color: @layout-sider-background;
  img {
    height: 35px;
    width: 40px;
    //border-radius: 50%;
    overflow: hidden;
    align-self: center;
  }
  span {
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
    white-space: nowrap;
  }
}

.admin-page-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  z-index: 999;
  background-color: #fff;
  .admin-page-header-right {
    flex: 1;
    height: inherit;
    .badge {
      padding-bottom: 16px;
      margin: 0 10px;
    }
    .dropdown {
      margin: 0 10px;
      padding-bottom: 23px;
      height: inherit;
    }
  }
}

.ant-layout-sider {
  transition: none !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.table-row-color-1 {
  background-color: @error-color;
  color: #fff;
  &:hover {
    color: #000c17;
  }
  &.ant-table-row-selected {
    color: #000c17;
  }
}

.table-row-color-2 {
  background-color: @warning-color;
  color: #fff;
  &:hover {
    color: #000c17;
  }
  &.ant-table-row-selected {
    color: #000c17;
  }
}

.table-row-color-3 {
  background-color: @info-color;
  color: #fff;
  &:hover {
    color: #000c17;
  }
  &.ant-table-row-selected {
    color: #000c17;
  }
}

.table-row-color-4 {

}

.table-row-color-ksf {
  background-color: #55e65f;
  color: #fff;
  &:hover {
    color: #000c17;
  }
  &.ant-table-row-selected {
    color: #000c17;
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

//.gauge-list{
//  margin-left:10px;
//  display:flex;
//  flex-wrap:wrap;
//  &-main{
//    width:279px;
//    height:140px;
//    display:flex;
//    align-items: center;
//    padding:40px;
//    img{
//      width:40px;
//      height:40px;
//      margin-right:21px;
//    }
//    &-msg{
//      div:last-child{
//        font-size: 25px;
//        font-weight: 600;
//      }
//    }
//  }
//  .background1{
//    background: url("./assets/a-3.png") 100% repeat;
//  }
//  .background2{
//    background: url("./assets/a-4.png") 100% repeat;
//  }
//  .background3{
//    background: url("./assets/a-11.png") 100% repeat;
//  }
//  .background4{
//    background: url("./assets/a-12.png") 100% repeat;
//  }
//  .background5{
//    background: url("./assets/a-13.png") 100% repeat;
//  }
//}

.notice-list{
  position: absolute;
  top: 50px;
  right: 130px;
  background: #fff !important;
  width: 17vw;
  height: 36vh;
  overflow: hidden;
}

@media print {
  .print-hidden{
    display: none;
  }
}

.ant-upload-list-item-info > span {
  display: flex !important;
  align-items: center;
}

